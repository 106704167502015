<template>
  <div class="edit-drawer">
    <el-dialog
      title="修改密码"
      :visible.sync="exist"
      width="30%"
      :close-on-click-modal="false"
      :show-close="false"
      @close="handleClose"
    >
      <el-form
        :model="formData"
        :rules="formRule"
        ref="form"
        class="edit-form"
        size="small"
        label-width="60px"
      >
        <el-form-item prop="Password" label="密码" class="inputItem">
          <el-input
            v-model="formData.Password"
            placeholder="请输入新密码"
            type="password"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" type="text">取 消</el-button>
        <el-button type="primary" @click="handleSave" :loading="saving" round
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import condCard from "@/components/cond-card";
import { apiEditUserPwd } from "@/api/auth";
export default {
  components: {
    // condCard,
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
    saveType: {
      type: String,
      default: () => "add",
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
  },
  data() {
    return {
      saving: false,
      direction: "rtl",
      formData: {
        Password: "",
      },
      formRule: {
        Password: {
          required: true,
          message: "请输入用户密码",
          trigger: "blur",
        },
      },
    };
  },
  computed: {},
  watch: {
    exist(newVal) {
      if (newVal !== true) {
        // 关闭重置表单值
        this.resetForm();
      }
    },
  },
  methods: {
    // 关闭弹窗
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    // 提交
    submitForm() {
      this.saving = true;
      const param = {
        ...this.formData,
      };

      apiEditUserPwd(this.detail._id, param)
        .then((res) => {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.$emit("success", res);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    // 重置表单值
    resetForm() {
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.inputItem {
  margin-bottom: 0;
  margin-right: 20px;
}
</style>
