<template>
  <div class="page-user">
    <content-header :title="title" :breadList="['系统设置', '用户管理']">
      <el-button
        slot="tools"
        type="primary"
        round
        @click="
          existDrawer = true;
          editType = 'add';
        "
      >
        <i class="el-icon-plus"></i>
        <span>新增用户</span>
      </el-button>
    </content-header>
    <div class="content-wrapper">
      <!-- 筛选条件 -->
      <div class="query-form" v-if="showQuery">
        <i class="el-icon-close" @click="showQuery = false"></i>
        <el-form
          :inline="true"
          v-if="showQuery"
          label-width="100px"
          ref="ruleForm"
          :model="formData"
        >
          <el-form-item label="姓名:">
            <el-input
              placeholder="姓名"
              v-model="formData.Name"
              style="width: 200px"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="账号:">
            <el-input
              placeholder="账号"
              v-model="formData.UserName"
              style="width: 200px"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号:">
            <el-input
              placeholder="手机号"
              v-model="formData.Mobile"
              style="width: 200px"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱:">
            <el-input
              placeholder="邮箱"
              v-model="formData.Email"
              style="width: 200px"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="角色:">
            <el-select
              v-model="formData.RoleId"
              style="width: 200px"
              placeholder="请选择或搜索用户角色"
              clearable
              filterable
            >
              <el-option
                :value="item._id"
                :label="item.Name"
                v-for="item in rolesList"
                :key="item._id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权限集:">
            <el-select
              v-model="formData.PermissionSetId"
              style="width: 200px"
              placeholder="请选择或搜索用户权限集"
              clearable
              filterable
            >
              <el-option
                :value="item._id"
                :label="item.Name"
                v-for="item in permissionsList"
                :key="item._id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否启用" prop="MessageChannel">
            <el-select
              v-model="formData.IsActive"
              placeholder="请选择"
              style="width: 200px"
              filterable
            >
              <el-option
                v-for="item in activeList"
                :label="item.Name"
                :value="item._id"
                :key="item._id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-button size="medium" type="info" @click="handleQuery" round
            >应用</el-button
          >
        </el-form>
      </div>
      <div class="headOperation" v-if="!showQuery">
        <img
          @click="showQuery = !showQuery"
          class="showQuery"
          src="@/assets/img/query-more.png"
          alt=""
        />
      </div>

      <el-table :data="tableData" v-loading="loading" class="table-customer">
        <el-table-column prop="Name" label="姓名"> </el-table-column>
        <el-table-column prop="UserName" label="账号"> </el-table-column>
        <el-table-column prop="Mobile" label="手机号"> </el-table-column>
        <el-table-column prop="Email" label="邮箱"> </el-table-column>
        <el-table-column prop="RoleName" label="角色"> </el-table-column>
        <el-table-column prop="PermissionSetName" label="权限集">
        </el-table-column>
        <el-table-column prop="PermissionSetName" label="是否启用">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.IsActive"
              @change="updateUser(row, $event)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="tool-wrap">
              <el-button type="text" @click="handleEdit(scope.row)">
                <i class="iconfont icon-edit"></i>
              </el-button>
              <!-- <el-button type="text" @click="handleChangePwd(scope.row)"
                >修改密码</el-button
              > -->
              <el-dropdown @command="handleCommand($event, scope.row)">
                <span class="el-dropdown-link">
                  <i class="iconfont icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="editPwd"
                    ><i class="iconfont icon-edit"></i
                    >修改密码</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        layout="prev, pager, next"
        :total="pagination.totalCount"
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        @current-change="handlePage"
      >
      </el-pagination>
    </div>
    <edit-drawer
      :exist.sync="existDrawer"
      :saveType="editType"
      :detail="curUser"
      @success="handleSaved"
    ></edit-drawer>
    <change-password
      :exist.sync="changePwdDrawer"
      :saveType="changePwdType"
      :detail="curUser"
      @success="handleSaved"
    ></change-password>
    <el-dialog title="删除确认" :visible.sync="delConfirmShow" width="30%">
      <span>确定删除该用户吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delConfirmShow = false" type="text">取 消</el-button>
        <el-button type="primary" @click="handleDelete" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ContentHeader from "@/views/layout/content-header";
import EditDrawer from "./components/edit-drawer";
import { apiGetUserList, apiDeleteUser } from "@/api/auth";
import ChangePassword from "./components/change-password.vue";

import {
  apiActivateOrDeactiveUser,
  apiGetRolesList,
  apiGetPermissionsList,
} from "@/api/auth/index.js";

export default {
  components: {
    EditDrawer,
    ContentHeader,
    ChangePassword,
  },
  data() {
    return {
      title: this.$route.meta.title,
      loading: false,
      tableData: [],
      existDrawer: false,
      delConfirmShow: false,
      editType: "add",
      curUser: {},
      pagination: {
        pageSize: 10,
        totalCount: 0,
        currentPage: 1,
      },
      changePwdDrawer: false,
      changePwdType: "edit",
      showQuery: false,
      formData: {
        Name: "", // 姓名
        UserName: "", // 账号
        Mobile: "", // 手机号
        Email: "", // 邮箱
        RoleId: "", // 角色
        PermissionSetId: "", // 权限集
        IsActive: -1, // 是否启用
      },
      // 是否启用
      activeList: [
        {
          Name: "全部",
          _id: -1,
        },
        {
          Name: "启用",
          _id: 1,
        },
        {
          Name: "禁用",
          _id: 0,
        },
      ],
      rolesList: [],
      permissionsList: [],
    };
  },
  methods: {
    handleQuery() {
      this.handlePage(1);
    },
    // 角色列表
    getRolesList() {
      apiGetRolesList({ type: 2 }).then((res) => {
        this.rolesList = res.roles;
      });
    },
    // 权限集列表
    getPermissionsList() {
      apiGetPermissionsList({ limit: 10000 }).then((res) => {
        this.permissionsList = res.permissionSets;
      });
    },
    getUserList() {
      this.loading = true;
      const { pageSize, currentPage } = this.pagination;
      const formData = JSON.parse(JSON.stringify(this.formData));
      if (formData.isActive == "") {
        formData.isActive = -1;
      }

      const param = {
        offset: pageSize * (currentPage - 1),
        limit: pageSize,
        ...formData,
      };
      apiGetUserList(param)
        .then((res) => {
          const list = res.users;
          list.forEach((item) => {
            item.IsActive = +item.IsActive == 1 ? true : false;
          });
          this.tableData = list;
          this.pagination.totalCount = res.meta.total_count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 点击编辑
    handleEdit(detail) {
      this.curUser = detail;
      this.editType = "edit";
      this.existDrawer = true;
    },
    // 点击修改密码
    handleChangePwd(detail) {
      this.curUser = detail;
      this.changePwdDrawer = true;
    },
    handleCommand(cmd, detail) {
      switch (cmd) {
        case "delete":
          this.curUser = detail;
          this.delConfirmShow = true;
          break;
        case "editPwd":
          this.handleChangePwd(detail);
          break;
      }
    },
    handleSaved() {
      this.existDrawer = false;
      this.changePwdDrawer = false;
      this.getUserList();
    },
    handleDelete() {
      apiDeleteUser(this.curUser._id).then(() => {
        this.delConfirmShow = false;
        this.getUserList();
      });
    },
    handlePage(index) {
      this.pagination.currentPage = index;
      this.getUserList();
    },
    updateUser(user, val) {
      apiActivateOrDeactiveUser(user._id, {
        IsActive: val === true ? 1 : 0,
      })
        .then(() => {
          this.$message({
            message: "更新成功",
            type: "success",
          });
        })
        .catch(() => {
          user.IsActive = !user.IsActive;
        });
    },
  },
  mounted() {
    this.getUserList();
    const query = this.$route.query;
    if (query.action) {
      this.curUser = {
        _id: query.id,
      };
      this.editType = query.action;
      this.existDrawer = true;
    }
  },
  created() {
    this.getRolesList();
    this.getPermissionsList();
  },
};
</script>

<style lang="less" scoped>
.page-user {
  .query-form {
    background: #f6f7f8;
    border-radius: 6px;
    position: relative;
    padding: 30px 90px 6px 16px;
    margin: 0 0 20px 0;
    .el-icon-close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    /deep/ .el-button--info {
      position: absolute;
      right: 10px;
      bottom: 16px;
      background: #4d4b58;
    }
  }

  .headOperation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .showQuery {
      cursor: pointer;
    }
  }

  .tool-wrap {
    /deep/ i {
      font-size: 20px;
      margin-right: 20px;
      color: #9a9a9e;
    }
  }
}
</style>
