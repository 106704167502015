<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
      :wrapperClosable="false"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>
            {{
              saveType === "add"
                ? "新增用户"
                : saveType === "show"
                ? "查看用户"
                : "修改用户"
            }}
          </span>
        </div>
        <div class="right">
          <el-button type="text" @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            round
            @click="handleSave"
            :loading="saving"
            :disabled="disabled"
            >保存</el-button
          >
        </div>
      </div>
      <div class="drawer-content">
        <el-form
          :model="formData"
          :rules="formRule"
          ref="form"
          class="edit-form"
          size="small"
          label-width="80px"
          :disabled="disabled"
        >
          <cond-card>
            <el-form-item label="姓名" prop="Name">
              <el-input
                v-model="formData.Name"
                placeholder="请输入用户姓名"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <el-form-item label="账号" prop="UserName">
              <el-input
                v-model="formData.UserName"
                placeholder="请输入账号"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="密码"
              prop="Password"
              v-if="saveType === 'add'"
            >
              <el-input
                v-model="formData.Password"
                placeholder="请输入密码"
                type="password"
                style="width: 440px"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item label="手机" prop="Mobile">
              <el-input
                v-model="formData.Mobile"
                placeholder="请输入手机"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱" prop="Email">
              <el-input
                v-model="formData.Email"
                placeholder="请输入电子邮箱"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <el-form-item label="角色" prop="RoleId">
              <el-select
                v-model="formData.RoleId"
                placeholder="请选择用户角色"
                style="width: 440px"
                filterable
              >
                <el-option
                  :value="item._id"
                  :label="item.Name"
                  v-for="item in rolesList"
                  :key="item._id"
                ></el-option>
              </el-select>
              <router-link to="/role/index?status=add">
                <el-button type="text" style="margin-left: 25px">
                  配置新的角色
                </el-button>
              </router-link>
            </el-form-item>
            <el-form-item label="权限集" prop="PermissionSetId">
              <el-select
                v-model="formData.PermissionSetId"
                placeholder="请选择用户权限集"
                style="width: 440px"
                filterable
              >
                <el-option
                  :value="item._id"
                  :label="item.Name"
                  v-for="item in permissionsList"
                  :key="item._id"
                ></el-option>
              </el-select>
              <router-link to="/auth/index?status=add">
                <el-button type="text" style="margin-left: 25px"
                  >配置新的权限
                </el-button>
              </router-link>
            </el-form-item>
            <el-form-item style="width: 440px" :label="'组织'" prop="OrgName">
              <el-select
                v-model="formData.OrgName"
                multiple
                placeholder="请选择组织"
                style="width: 440px"
                filterable
              >
                <el-option
                  v-for="item in OrganizeList"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否启用" prop="IsActive">
              <el-switch v-model="formData.IsActive"></el-switch>
            </el-form-item>
          </cond-card>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import // apiAddChannel,
// apiUpdateChannel,
// apiGetChannelDetail,
"@/api/channel";
import condCard from "@/components/cond-card";

import {
  apiCreateUser,
  apiEditUser,
  apiGetRolesList,
  apiGetPermissionsList,
  apiGetUsersDetail,
} from "@/api/auth/index.js";
export default {
  components: {
    condCard,
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
    saveType: {
      type: String,
      default: () => "add",
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
  },
  data() {
    return {
      saving: false,
      direction: "rtl",
      formData: {
        Name: "",
        UserName: "",
        Password: "",
        Mobile: "",
        Email: "",
        RoleId: "",
        PermissionSetId: "",
        IsActive: true,
        OrgName: [],
      },
      formRule: {
        Name: {
          required: true,
          message: "请输入用户名称",
          trigger: "blur",
        },
        Password: {
          required: true,
          message: "请输入密码",
          trigger: "blur",
        },
        UserName: {
          required: true,
          message: "请输入用户账号",
          trigger: "blur",
        },
        Mobile: {
          required: false,
          message: "请输入手机",
          trigger: "blur",
        },
        Email: {
          required: false,
          message: "请输入用户邮箱",
          trigger: "blur",
        },
        RoleId: {
          required: true,
          message: "请选择用户角色",
          trigger: "change",
        },
        PermissionSetId: {
          required: true,
          message: "请选择用户权限集",
          trigger: "change",
        },
        OrgName: {
          required: true,
          message: "请选择组织",
          trigger: "change",
        },
      },
      rolesList: [],
      permissionsList: [],
      disabled: false,
    };
  },
  computed: {
    ...mapGetters({
      channelLabelMap: "common/channelLabelMap",
      userFields: "common/userFields",
    }),
    OrganizeList() {
      const channel =
        this.userFields.find((item) => item.APIName === "OrgName") || {};
      return channel.PicklistOptions;
    },
  },
  watch: {
    exist(newVal) {
      if (newVal === true) {
        this.$nextTick(() => {
          this.getDetail();
        });
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    submitForm() {
      this.saving = true;
      const param = JSON.parse(JSON.stringify(this.formData));
      param.IsActive = param.IsActive ? 1 : 0;
      let prom;
      if (this.saveType === "add") {
        prom = apiCreateUser(param);
      } else {
        prom = apiEditUser(this.detail._id, param);
      }
      prom
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$emit("success", res);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    // 用户详情
    getDetail() {
      if (this.saveType === "edit" || this.saveType === "show") {
        if (this.saveType === "show") {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
        apiGetUsersDetail(this.detail._id).then((res) => {
          Object.assign(this.formData, {
            Name: res.Name,
            UserName: res.UserName,
            Password: res.Password,
            Mobile: res.Mobile,
            Email: res.Email,
            RoleId: res.RoleId,
            PermissionSetId: res.PermissionSetId,
            IsActive: res.IsActive == 1 ? true : false,
            OrgName: res.OrgName || [],
          });
        });
      }
    },
    // 角色列表
    getRolesList() {
      apiGetRolesList({ type: 2 }).then((res) => {
        this.rolesList = res.roles;
      });
    },
    // 权限集列表
    getPermissionsList() {
      apiGetPermissionsList({ limit: 10000 }).then((res) => {
        this.permissionsList = res.permissionSets;
      });
    },
  },
  created() {
    this.getRolesList();
    this.getPermissionsList();
  },
};
</script>

<style lang="less" scoped>
.edit-drawer {
  /deep/ .el-drawer {
    width: 72% !important;
    background: #f6f7f8;
    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }
    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    .left {
      font-size: 24px;
      line-height: 32px;
      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }

  .cond-combination {
    .title {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
    }
    .content {
      padding: 20px;
      padding-bottom: 6px;
      background: #f6f7f8;
      border-radius: 6px;
    }
  }
  .plan-types {
    overflow: hidden;
    margin-bottom: -16px;
  }
}
</style>
